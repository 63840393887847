package com.rabbitsign.common

// The list of supported languages,
// based on https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
enum class IsoLangCode(val languageName: String) {
    en("English"),
    fr("French"),
    pt("Portuguese"),
    ge("Georgian")
}
