package com.rabbitsign.common

import com.rabbitsign.common.util.unknownKeysSkippedJson
import kotlinx.serialization.Serializable

// make sure that any changes here are reflected in the OAuthHandler Python Lambda code!
// a hardcoded list of what information is currently used. see INFO_KEYS in exchange_code_for_token.py
@Serializable
data class UserInfo(val given_name: String, val family_name: String, val email: Email, val exp: Long) {
    companion object Factory {
        fun newInstance(decodedToken: String): UserInfo {
            return unknownKeysSkippedJson.decodeFromString(serializer(), decodedToken)
        }
    }
}
